:root {
  /* Main colors */
  --color-BG: #947C9C;
  --color-HDG: #947C9C;
  --color-BORDER: #AD9BB3;
  --color-SHADOW: #C3B5C7;
  --color-HDG-SHADOW: rgba(0, 0, 0, 0.1);
  --color-INPUT-BG: #e9f0fe;
  --color-CONSTRUCTION: #AD9BB3;
  --color-MAIN-HDG: #FFF;
  --color-MAIN-TXT: #DFDFDF;
  --color-EVENT-BG: #55a014;
  --color-EVENT-SHADOW: #284b09;
  --color-EVENT-BG: #947C9C;
  --color-EVENT-SHADOW: #666;
  --color-BG-LIGHT: rgb(238, 236, 242);
  --color-BG-MEDIUM: rgba(148, 124, 156, .8);

  --color-ERROR: #dc143c;
  --color-INFO: #6495ed;
  --color-OK: #228b22;

  --color-LogoPink: #ad9bb3;
  --color-LogoPink-hover: #947c9c;
  --color-HeroBG: #f1f2f6;
  --color-ColsBG: #f8f9f6;

  /* NavBar colors */
  --color-NavBar: #EEE;
  --color-NavBar-BG: var(--color-BG);
  --color-NavBar-BORDER: var(--color-BORDER);
  --color-NavBar-HOVER: #FFF;
  --color-NavBar-SHADOW: var(--color-SHADOW);

  /* Footer colors */
  --color-Footer-BG: var(--color-BG);
  --color-Footer-SHADOW: var(--color-SHADOW);
  --color-Footer-HDG: #FFF;
  --color-Footer-TXT: #DFDFDF;


  --SHADOW: 0rem 0.3rem 0.3rem;
  --HDG-SHADOW: 0.0rem 0.1rem 0.1em;
  --navBar-SHADOW: 0.0rem 0.1rem 0.1rem;
  --navBar-HOVER-displace: -0.1rem;

  --navBar-height: 5rem;
  --navBar-Mobile-width: 10rem;

  --navBar-BORDER-radius: 1.5rem;

  --ObjectSpacing-bottom: 2rem;

  /* Fonts */
  --font-system-ui: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  --font-system-ui-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono", "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro", "Fira Mono", "Droid Sans Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: var(--font-system-ui);
  font-size: 1rem;
}

.pointer {
  cursor: pointer;
}

#scrollToTop {
  display: none;
  position: fixed;
  bottom: 5%;
  right: 5%;
  padding: 1em;
  background: rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 0.5rem;
  color: #FFF;
  border: 0;
}

#scrollToTop:hover,
#scrollToTop:active {
  background: rgba(0, 0, 0, 0.5);
  color: #FFF;
}

.scroll-container {
  position: absolute;
  top: 0;
  right: 5%;
  height: 100%;
  /* background-color: red; */
}

/* hide button when page loads first time */
.scroll-container::before {
  content: '';
  display: block;
  /* height: 100vh; */
  pointer-events: none;
}

/* fixed to right bottom of the page */
.scroll-container a {
  text-decoration: none;
  position: sticky;
  top: 90vh;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.5em;
  background: rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 0.5rem;
  color: #FFF;
  border: 0;
  margin-bottom: 1rem;
}

.scroll-container a:is(:hover, :active) {
  background: rgba(0, 0, 0, 0.5);
  color: #FFF;
}

.button {
  cursor: pointer;
}

.button:hover {
  filter: drop-shadow(0.1em 0.1em 1px var(--color-SHADOW));
}

img {
  max-width: 100%;
}

a.btn-close:is(:link, :visited) {
  border: outset 0.1em var(--color-BG);
  background: var(--color-BG);
  text-decoration: none;
  font-size: 1.8rem;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  border-radius: 0.2em;
  justify-self: right;
  margin: 0.2em 0.2em;
  color: var(--color-MAIN-HDG);
}

a.btn:is(:link, :visited) {
  background: var(--color-BG);
  color: var(--color-MAIN-HDG);
  border-radius: var(--navBar-BORDER-radius);
  padding: 1em;
  text-decoration: none;
  box-shadow: var(--HDG-SHADOW) var(--color-SHADOW);
}

a.btn-inline:is(:link, :visited) {
  background: var(--color-BG);
  color: var(--color-MAIN-HDG);
  border-radius: calc(var(--navBar-BORDER-radius) / 5);
  padding: 0.1em 0.5em;
  text-decoration: none;
}

a:is(.btn, .btn-inline):is(:active, :hover) {
  background: var(--color-BORDER);
}

.errMsg,
.infoMsg,
.okMsg {
  font-size: .8rem;
  background: rgba(255, 255, 255, 0.6);
  border-radius: calc(var(--navBar-BORDER-radius) / 4);
  margin: 0;
  padding: .3em .5em;
}

.errMsg {
  color: var(--color-ERROR);
}

.infoMsg {
  color: var(--color-INFO);
}

.okMsg {
  color: var(--color-OK);
}

.errMsgForm {
  height: 1rem;
  font-style: italic;
}

.crumbs {
  margin: 0rem 0rem 1rem 1rem;
}

.crumbs>span::before {
  content: ' / ';
}

.crumbs>span {
  text-transform: uppercase;
  font-weight: 900;
}

.crumbs a:is(:link, :visited) {
  color: var(--color-BG);
  font-weight: 900;
}

.crumbs a:is(:active, :hover) {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--color-HDG);
  text-shadow: var(--HDG-SHADOW) var(--color-HDG-SHADOW);
  margin-top: 0;
  /* font-family: 'Lilita One', sans-serif; */
}

.popup {
  --_pointer-size: 10px;
  --_padding: 5px;
  --_color-bg: #FFF;
  --_box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  --_color-hdg: var(--color-BG);
  --_color-txt: var(--color-BORDER);

  position: relative;
  pointer-events: none;
  background: #FFF;
  box-shadow: var(--_box-shadow);
  border-radius: var(--_pointer-size);
  background: var(--_color-bg);
  color: var(--_color-txt);
}

.popup> :is(h1, h2, h3, h4, h5) {
  color: var(--_color-hdg);
}

.popup>* {
  position: relative;
  margin: 0px var(--_padding);
  z-index: 5;
}

.popup> :first-child {
  padding-top: var(--_padding);
}

.popup> :last-child {
  padding-bottom: var(--_padding);
}

.popup::before {
  content: "";
  position: absolute;
  left: calc(var(--_pointer-size) / -2);
  top: var(--_pointer-size);
  width: var(--_pointer-size);
  height: var(--_pointer-size);
  background: var(--_color-bg);
  box-shadow: var(--_box-shadow);
  transform: rotateZ(45deg);
  z-index: 0;
}

.popup::after {
  content: "";
  position: absolute;
  border-radius: inherit;
  left: 0px;
  top: calc(var(--_pointer-size) / 2);
  width: var(--_padding);
  height: calc(var(--_pointer-size) * 2);
  background: var(--_color-bg);
  z-index: 1;
}

form,
.form-nr-bus {
  /* --_color-BG: var(--color-LogoPink); */
  --_color-BG: var(--color-BG-MEDIUM);
  --_color-FG: var(--color-Footer-HDG);
  --_border-radius: calc(var(--navBar-BORDER-radius) / 4);
  display: grid;
  gap: 0.5rem;
  background: var(--_color-BG);
  color: var(--_color-FG);
  border-radius: var(--_border-radius);
  border: 0.2em solid var(--color-NavBar-SHADOW);
  padding: 1rem;
  box-shadow: var(--SHADOW) var(--color-BORDER);
}

form>* {
  display: grid;
  /* background: var(--color-SHADOW);
  background: var(--color-INPUT-BG); */
  background: var(--color-BG-LIGHT);
  border-radius: var(--_border-radius);
}

.form-nr-bus {
  background: transparent;
  margin: 0;
  padding: 0;
  border: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
}

.form-nr-bus>* {
  border-radius: inherit;
}

.form-nr-bus .bus-nr {
  width: 100%;
}

.form-nr-bus span.validation {
  top: 1.1rem;
}

form fieldset {
  display: grid;
  gap: 0.5rem;
  background: transparent;
  border: solid 1px var(--color-BG-LIGHT);
}

fieldset>legend {
  width: max-content;
}

form fieldset input {
  background: var(--color-BG-LIGHT);
}

form label {
  padding-left: 0.5em;
}

form input {
  border-radius: inherit;
  border: 0.1em solid var(--color-NavBar-SHADOW);
  padding: 0.5em 1em;
  transition: outline 0.5s ease-in-out;
  outline: none;
  font-size: 1rem;
}

input {
  accent-color: var(--color-BG);
}

form select,
form ul {
  --_title-height: 1.5em;
  border-radius: var(--_border-radius);
  border: 0.1em solid var(--color-MAIN-HDG);
  font-size: 1rem;
  position: relative;
  padding: 0;
}

form select::before,
form ul::before {
  content: attr(data-title);
  font-size: 1.05rem;
  font-weight: bold;
  background: var(--color-BG);
  color: var(--color-MAIN-HDG);
  margin-bottom: 0.1em;
  padding: 0.2em 0.5em;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  /* position: absolute; */
  width: 100%;
  height: var(--_title-height);
  left: 0;
  top: 0;
}

form select option,
form ul li {
  background: var(--_color-BG);
  padding: 1.2rem 0.3em 0.3rem 0.3em;
  color: var(--color-MAIN-HDG);
  font-family: var(--font-system-ui-mono);
  font-size: 0.9rem;
  list-style: none;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 2rem 1fr;
  align-items: center;
}

form ul .price {
  --_height: 1.2rem;
  --_padding: 0.15rem;
  position: relative;
  width: 100%;
  pointer-events: none;
  border-bottom: solid 0.1rem var(--color-BG);
}

form ul .price:last-child {
  border-bottom: none;
}

form ul .price::after {
  content: "€ " attr(data-price) " / kind";
  position: absolute;
  font-size: calc(var(--_height) - var(--_padding) * 2 - 0.1rem);
  top: 0;
  right: 0;
  background: var(--color-BG);
  padding: var(--_padding) 0.5em;
  border-bottom-left-radius: var(--_border-radius);
}

form ul li .btn {
  cursor: pointer;
  text-decoration: underline;
}

form ul li .btn:hover {
  text-decoration: none;
}

form ul li :first-child:hover {
  background: var(--color-BORDER);
}

form ul li :first-child {
  display: grid;
  justify-content: center;
  color: var(--color-MAIN-HDG);
  background: var(--color-BG);
  border-radius: var(--navBar-BORDER-radius);
  font-size: 2rem;
}

form select option:first-child {
  margin-top: var(--_title-height);
}

form ul li:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

form ul li>.span {
  grid-column: 1 / 3;
  width: 100%;
  font-size: inherit;
  background: inherit;
}

form select option+option,
form ul li+li {
  margin-top: 0.1em;
}

form .aantal-kids,
.aantal-kids :is(h1, h2, h3, h4, h5, h6) {
  background: transparent;
  color: var(--_color-FG);
}

.asterisk-line {
  stroke: black;
}

form input:is(:required, .validation) {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8"><path stroke="%23947C9C" d="M4 0.5 L 4 7.5" /><path stroke="%23947C9C" d="M1 2 L 7 6" /><path stroke="%23947C9C" d="M7 2 L 1 6" /></svg>');
  background-position: 0.5rem;
  background-size: 1em;
  background-repeat: no-repeat;
  padding-left: 2rem;
}

form input.validation {
  background-image: none;
}

form input::placeholder {
  color: var(--color-BG);
}

form input:not([type="submit"]) {
  box-shadow: var(--navBar-SHADOW) var(--color-BG);
}

form input:focus:not([type="submit"], [type="radio"], [type="checkbox"]) {
  outline-style: dashed;
  outline-color: var(--color-BG);
  outline-offset: -0.4em;
  outline-width: 0.15em;
}

form .aantal-kids input,
form>.aantal-kids>input:focus {
  transition: none;
  outline: none;
}

form input[type="submit"],
form .submit,
form>ul {
  color: #FFF;
  background: var(--color-BG);
  border-color: var(--color-BG);
  box-shadow: var(--navBar-SHADOW) rgba(0, 0, 0, 0.4);
}

form.kidTaxForm>*:last-child {
  margin-top: 1rem;
}

form .submit {
  text-decoration: none !important;
  font-weight: bold;
  display: inline-block;
  width: 100%;
  text-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
}

form .submit .icon {
  font-size: 1.5rem;
  margin-left: 1rem;
  line-height: 2rem;
  vertical-align: middle;
  filter: drop-shadow(0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3));
}

.icon.spinner {
  position: relative;
}

.icon.spinner>* {
  animation: spinSpinner 1s steps(8, end) infinite;
}

@keyframes spinSpinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

form input[type="submit"]:is(:hover, :active),
form .sumbit:is(:hover, :active) {
  outline-style: solid;
  outline-color: var(--color-INPUT-BG);
  outline-width: 0.15em;
  outline-offset: -0.1em;
}

form input:disabled {
  transition: none;
  outline: none !important;
  background: var(--color-BORDER);
}

.adminMap {
  width: 100%;
  height: 50vh;
  min-height: 30rem;
  border-radius: 1rem;
  border: solid 0.2rem var(--color-MAIN-HDG);
  box-shadow: 0rem 0rem 0.3rem rgba(148, 124, 156);
}

.adminKampdagChange {
  margin: 0 auto;
  display: grid;
  /* max-width: 30rem; */
  grid-template-columns: 4rem minmax(1fr, 15rem) 6rem;
}

.adminKampdagChange>*:not(select) {
  background: transparent;
}

.adminKampdagChange>label,
.kampDagen>legend {
  padding: 0;
  font-weight: bold;
}

.adminKampdagChange>div {
  justify-self: right;
}

.adminKampdagChange .kamp {
  text-transform: capitalize;
  box-shadow: var(--navBar-SHADOW) rgba(0, 0, 0, 0.4);
}

.adminKampdagChange .kampDagen {
  margin: 0;
  padding: 0.5rem;
  box-shadow: var(--navBar-SHADOW) rgba(0, 0, 0, 0.4);
}

.adminKampdagChange .kampDagen div {
  display: grid;
  grid-template-columns: 1rem 1fr;
}

.adminKampdagChange .kampDagen div>* {
  margin: 0;
  padding: 0rem .5rem;
  box-shadow: none;
}

.adminKampdagChange .kampDagen div label {
  display: grid;
  row-gap: 0rem;
  column-gap: 1rem;
  grid-template-columns: minmax(6rem, 1fr) 4rem 3rem;
  grid-template-areas:
    "a c d"
    "b b b";
  align-items: center;
}

.adminKampdagChange .kampDagen div label>*:nth-child(1) {
  grid-area: a;
  font-weight: bold;
}

.adminKampdagChange .kampDagen div label>*:nth-child(2) {
  grid-area: b;
  color: var(--color-MAIN-TXT);
}

.adminKampdagChange .kampDagen div label>*:nth-child(3) {
  grid-area: c;
  background: var(--color-BG);
  width: 100%;
  text-align: right;
  padding: 0 0.1rem;
}

.adminKampdagChange .kampDagen div label>*:nth-child(4) {
  grid-area: d;
  background: var(--color-BG);
  width: 100%;
  text-align: right;
  padding: 0 0.1rem;
}

.refundTransaction .refundTypes {
  display: grid;
  grid-template-areas:
    "a a"
    "b b"
    "c d";
  justify-content: center;
  margin: 0.5rem;
  padding: 0 0 0.5rem 0;
  border-radius: 0.5rem;
  width: 100%;
}

.refundTransaction .refundTypes>*:nth-child(1) {
  grid-area: a;
}

.refundTransaction .refundTypes>*:nth-child(2) {
  grid-area: b;
}

.refundTransaction button {
  background: var(--color-BG);
  color: var(--color-MAIN-HDG);
  font-weight: bold;
  border: solid 0.2rem var(--color-BORDER);
  padding: 0.3rem 1rem;
  border-radius: .5rem;
  filter: drop-shadow(0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3));
}

.refundTransaction button:disabled {
  background: var(--color-BG-LIGHT);
  color: var(--color-BG-MEDIUM);
  border-color: var(--color-BG-LIGHT);
  filter: none;
}

.refundTransaction button:not(:disabled):is(:hover, :active) {
  filter: none;
}

.refundTransaction button+button {
  margin-left: .5rem;
}

.adminTransactions a {
  background: none !important;
  color: inherit !important;
}

.adminTransactions,
.adminKlanten {
  --_PadAndRadius: 0.5rem;
  background: var(--color-BG-LIGHT);
  padding: 0;
  border-radius: var(--_PadAndRadius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow: scroll;
  height: 15rem;
}

.adminSearch.search {
  --_PadAndRadius: 0.5rem;
  --_IconSize: 0.5rem;
  padding: calc(var(--_PadAndRadius) * .7);
  background: var(--color-BG);
  border-radius: var(--_PadAndRadius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: var(--color-MAIN-HDG);
}

.adminSearch.search .icon {
  position: absolute;
  padding: var(--_IconSize);
  color: var(--color-BG);
}

.adminSearch.search input[type="text"] {
  width: 100%;
  padding: var(--_IconSize);
  padding-left: calc(var(--_IconSize) * 4);
  border-radius: var(--_PadAndRadius);
  border: none;
  font-size: 1rem;
  color: var(--color-BG);
}

.adminSearch.search fieldset {
  gap: 0;
  margin: .3rem 0 0 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, 2rem 5rem);
}

.adminTransactions ul,
.adminKlanten ul {
  list-style-type: none;
  margin: var(--_PadAndRadius) 0;
  padding: 0;
}

.adminTransactions ul>li {
  display: grid;
  gap: 0.5rem;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(10.5rem, 1fr));
  padding: 0.2rem var(--_PadAndRadius);
  border: 0.01rem solid rgba(0, 0, 0, 0.3);
  border-left: none;
  border-right: none;
}

.adminKlanten ul>li {
  display: grid;
  gap: 0.5rem;
  align-items: center;
  grid-template-columns: 2rem 1fr 1rem 2rem 1fr;
  padding: 0.2rem var(--_PadAndRadius);
  border: 0.01rem solid rgba(0, 0, 0, 0.3);
  border-left: none;
  border-right: none;
}


:is(.adminTransactions, .adminKlanten) ul>li:nth-child(odd) {
  background: rgba(255, 255, 255, 0.7);
}

/*
.adminKlanten {
  display: grid;
  background: var(--color-BG-MEDIUM);
  color: var(--color-MAIN-HDG);
  border-radius: 0.3rem;
  overflow: hidden;
  box-shadow: var(--navBar-SHADOW) rgba(0, 0, 0, 0.4);
}
/
.adminKlanten>* {
  display: grid;
  grid-template-columns: 3rem 1fr 11rem;
  padding: 0.1rem 0.3rem;
}

/*
.adminKlanten>*:nth-child(even) {
  background: var(--color-BG-LIGHT);
  color: var(--color-BG);
}

.adminKlanten>*:first-child {
  background: var(--color-BG);
  color: var(--color-MAIN-HDG);
}
*/

.adminKlant {
  --adminKlant-Width: 20rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(var(--adminKlant-Width), 1fr));
  grid-template-rows: 15rem;
}

.adminKlant>.details {
  display: grid;
  width: minmax(var(--adminKlant-Width), 1fr);
  row-gap: 0.5rem;
  grid-template-columns: 3rem 1fr;
  align-items: center;
  background: var(--color-BG-LIGHT);
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.adminKlant>.details>*:nth-child(odd) {
  font-size: 1.3rem;
  display: grid;
  justify-content: center;
}

.adminKampjes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: .2rem;
  border-radius: 0.5rem;
  overflow: scroll;
  max-height: 20rem;
}

.adminKampjes>* {
  --_var: .5rem;
  font-size: 1.2rem;
  font-weight: bold;
  padding: var(--_var);
  border-radius: var(--_var);
  color: var(--color-MAIN-HDG);
  text-align: center;
  text-transform: capitalize;
  box-shadow: var(--navBar-SHADOW) rgba(0, 0, 0, 0.4);
}

.adminKampjes>*:is(:hover, :active) {
  outline-style: solid;
  outline-color: var(--color-INPUT-BG);
  outline-width: 0.15em;
  outline-offset: -0.1em;
}

.adminKampjes>.addNew {
  background: var(--color-BG);
}

.EditKamp>*:first-child {
  text-transform: capitalize;
}

.EditKamp>.colors {
  border-radius: inherit;
  background: transparent;
  overflow: hidden;
  display: grid;
  gap: 0.3rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.EditKamp>.colors>*:not(legend) {
  border-radius: inherit;
  padding: .5rem;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}

.EditKamp>.colors>*:is(:hover, :active):not(legend) {
  outline-style: solid;
  outline-color: var(--color-INPUT-BG);
  outline-width: 0.15em;
  outline-offset: -0.1em;
}

.kampDagen {
  border-radius: 0.5rem;
  max-height: 20rem;
  overflow: scroll;
}

.kampDag {
  display: grid;
  margin-bottom: 0.5rem;
  padding: 0.3rem;
  background: var(--color-ERROR);
  color: var(--color-MAIN-HDG);
  border: solid 0.01rem var(--color-BG);
  border-radius: 0.5rem;
  box-shadow: var(--navBar-SHADOW) rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-items: left;
  column-gap: 0.3rem;
  grid-template-columns: 2rem 1fr 2fr 5rem;
  grid-template-areas:
    "a b b e"
    "a c d f";
}

.kampDag.nieuw {
  display: grid;
  grid-template-areas: "a a a a a";
}

.kampDag>*:first-child {
  position: relative;
  grid-area: a;
  display: grid;
  justify-self: center;
}

.kampDag>*:nth-child(2) {
  grid-area: b;
  font-weight: bold;
}

.kampDag>*:nth-child(3) {
  grid-area: c;
  text-transform: capitalize;
}

.kampDag>*:nth-child(4) {
  grid-area: d;
}

.kampDag>*:nth-child(5) {
  grid-area: e;
  font-weight: bold;
  justify-self: right;
  /* width: 100%; */
}

.kampDag>*:nth-child(6) {
  grid-area: f;
  font-size: 0.8rem;
  justify-self: right;
}

.EditKampDag>select {
  text-transform: capitalize;
  color: var(--color-MAIN-HDG);
  font-weight: bold;
}

.EditKampDag>.checkbox {
  display: grid;
  align-items: center;
}

.EditKampDag>.checkbox>label {
  font-size: 1.2rem;
  font-weight: bold;
}

.EditKampDag>.price {}

span.validation {
  background: transparent;
  position: relative;
  top: 1.6rem;
  left: 0.5rem;
  width: 100%;
  height: 0rem;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  /* justify-items: flex-end; */
}

span.validation>* {
  position: absolute;
}

svg:is(.valid, .invalid) {
  background: var(--color-BG-LIGHT);
  border-radius: 2em;
  padding: 0.1em;
  font-size: 1.3rem;
}

svg.valid {
  color: #55a014;
}

svg.invalid {
  color: hsl(360, 78%, 35%);
}

form p {
  font-size: 0.8rem;
  background-color: var(--color-LogoPink);
  color: var(--color-Footer-HDG);
  margin: 0;
}

form p:is(.instructions, .offscreen) {
  background: transparent;
}

form p:is(.instructions, .offscreen)>svg {
  width: 13px;
}

form p.offscreen {
  display: none;
}

form p.instructions {
  display: block;
}

form .checkbox {
  display: grid;
  grid-template-columns: max-content 1fr;
  background: transparent;
  font-size: 1rem;
}

form .checkbox>input[type="checkbox"] {
  transition: none;
  outline: none;
  box-shadow: none;
}

form>p {
  display: block;
}

form a:is(:link, :visited) {
  background: inherit;
  color: var(--color-MAIN-TXT);
  justify-self: start;
}

form a:is(:active, :hover) {
  color: var(--color-MAIN-HDG);
  text-decoration: none;
}

a.form:is(:link, :visited) {
  --_var: 0.1rem;
  background: var(--color-BG);
  color: var(--color-MAIN-TXT);
  padding: var(--_var);
  border-radius: var(--_var);
}

a.form:is(:active, :hover) {
  color: var(--color-MAIN-HDG);
  text-decoration: none;
}

.loading {
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><linearGradient id="a11"><stop offset="0" stop-color="%23947C9C" stop-opacity="0"></stop><stop offset="1" stop-color="%23947C9C"></stop></linearGradient><circle fill="none" stroke="url(%23a11)" stroke-width="30" stroke-linecap="round" stroke-dasharray="0 44 0 44 0 44 0 44 0 360" cx="100" cy="100" r="70" transform-origin="center"><animateTransform type="rotate" attributeName="transform" calcMode="discrete" dur="1" values="360;324;288;252;216;180;144;108;72;36" repeatCount="indefinite"></animateTransform></circle></svg>');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 3em;
  height: 100%;
  width: 100%;
  display: grid;
  align-content: center;
  justify-items: center;
}

.loading>* {
  margin-top: 4em;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.container {
  max-width: 1200px;
  min-width: 360px;
  width: 95%;
  margin: 0 auto;
  margin-bottom: var(--ObjectSpacing-bottom);
}

.navBar {
  position: sticky;
  top: 0;
  height: var(--navBar-height);
  background: var(--color-NavBar-BG);
  /* display: flex;
  justify-content: space-between; */
  display: grid;
  grid-template-columns: 1fr max-content;

  padding: 0 1rem 0 1rem;
  box-shadow: var(--SHADOW) var(--color-NavBar-SHADOW);
  border: solid 0.3rem var(--color-NavBar-BORDER);
  border-bottom-left-radius: var(--navBar-BORDER-radius);
  border-bottom-right-radius: var(--navBar-BORDER-radius);
  border-top: 0;
  border-bottom: 0;
  z-index: 100;
}

.navSession {
  --_font-size: 2.5rem;
  display: grid;
  gap: 0.5rem;
  grid-auto-flow: column;
  align-content: center;
  color: var(--color-MAIN-HDG);
  font-size: 2.6rem;
}

.navSession a:is(:link, :visited) {
  color: var(--color-MAIN-TXT);
}

.navSession a:is(:active, :hover) {
  color: var(--color-MAIN-HDG);
  filter: drop-shadow(var(--navBar-SHADOW) var(--color-NavBar-SHADOW));
}

.navSession .cart {
  position: relative;
}

.navSession .cart::after {
  content: attr(data-items-in-bag);
  position: absolute;
  top: calc(var(--_font-size) * 0.42);
  left: 0;
  font-size: 1rem;
  width: 100%;
  text-align: center;
  color: var(--color-MAIN-HDG);
}

.nav {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  border: 0;
}

.nav a {
  color: var(--color-NavBar);
  font-size: 1.3rem;
  font-weight: bold;
  text-decoration: none;
}

.nav a:hover,
.nav a:active {
  color: var(--color-NavBar-HOVER);
  text-shadow: var(--navBar-SHADOW) var(--color-NavBar-SHADOW);
}

.nav li+li {
  margin: 2.5rem 0rem 0rem 1.2rem;
}

.logo {
  max-height: var(--navBar-height);
  fill: #FFF;
  display: flex;
  padding: 0.3rem;
}

.logo:hover,
.logo:active {
  fill: var(--color-NavBar-HOVER);
  filter: drop-shadow(var(--navBar-SHADOW) var(--color-NavBar-SHADOW));
}

.fullLogo {
  display: none;
}

#selected {
  color: var(--color-NavBar-SHADOW);
  fill: var(--color-NavBar-SHADOW);
  text-shadow: none;
  filter: drop-shadow(0 0 0);
}

.mobileLogoTitle,
.btnMobileLogo {
  display: none;
}

.page {
  flex-grow: 1;
  width: 100%;
}

.flex,
.flex-row,
.flex-col,
.flex-align-middle,
.flex-space-around {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.flex-align-middle {
  align-items: center;
}

.flex-space-around {
  justify-content: space-around;
}

:is(.flex, .flex-row, .flex-align-middle, .flex-space-around) a+a {
  margin-left: 1.5em;
}

.col {
  padding: 0rem 0.5rem;
}

.payOpts {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 1rem;
}

.payOpt {
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  justify-items: center;
  gap: 0.3rem;
}

.payOpt label {
  font-size: 1rem;
  font-weight: bold;
}

.payOpt img {
  max-width: 3rem;
}

.grid {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 1em;
}

.grid-fit-max {
  display: grid;
}

.grid-center {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  justify-items: center;
}

.gridItem {
  color: inherit;
}

.accountLayout {
  display: grid;
  gap: 1rem;
}

.accountNav {
  border: solid 0.1rem var(--color-BORDER);
  border-radius: calc(var(--navBar-BORDER-radius) / 2);
  box-shadow: var(--SHADOW) var(--color-SHADOW);
}

.accountNav>ul {
  font-size: 1rem;
  list-style: none;
  padding: 0.3rem;
  margin: 0;
  border-radius: inherit;
}

.accountNav>ul>li {
  background: var(--color-BG-LIGHT);
  padding: .3rem 1rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 1rem;
  align-items: center;
}

.accountNav>ul>li:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.accountNav>ul>li:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.accountNav>ul>li>:first-child {
  /* Icon */
  font-size: 1.5rem;
  display: grid;
}

.accountNav>ul>li:is(:hover, :active),
.accountNav>ul>li.selected {
  background: var(--color-BG-MEDIUM);
  color: var(--color-MAIN-HDG);
}

.accountNav>ul>li+li {
  margin-top: .2rem;
}

.accountPage {
  margin-top: 1em;
  padding-top: 1em;
  border-top: solid 1px var(--color-BG);
}

.admin-agenda {
  display: grid;
  gap: .3rem;
  border: solid 0.1rem var(--color-BORDER);
  border-radius: calc(var(--navBar-BORDER-radius) / 2);
  box-shadow: var(--SHADOW) var(--color-SHADOW);
  padding: .3rem;
  height: fit-content;
}

.admin-agenda>* {
  color: var(--color-BG);
  background: var(--color-BG-LIGHT);
  padding: .3rem;
}

.admin-agenda>*:is(:hover, :active):not(:first-child, :nth-child(2)) {
  color: var(--color-MAIN-HDG);
  background: var(--color-BG-MEDIUM);
}

.admin-agenda>*:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  font-weight: bold;
  display: grid;
  grid-template-columns: 2.5rem 1fr 2.5rem;
  justify-items: center;
  align-items: center;
  background: var(--color-BG);
  color: var(--color-MAIN-HDG);
}

.admin-agenda>*:first-child>*:first-child,
.admin-agenda>*:first-child>*:last-child {
  font-size: 1.5rem;
  display: grid;
}

.admin-agenda>*:nth-child(2) {
  font-weight: bold;
  display: grid;
  justify-content: center;
  background: inherit;
}

.admin-agenda>*:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.admin-agenda-li {
  padding: .3rem 1rem;
  display: grid;
  grid-template-columns: 1.5rem 1fr 1.5rem;
  gap: 1rem;
}

.ShowKid {
  border-radius: calc(var(--navBar-BORDER-radius) / 2);
  padding: 1rem;
  background: var(--color-BG);
  color: var(--color-MAIN-HDG);
  width: fit-content;
  margin: 0 auto;
  box-shadow: var(--SHADOW) var(--color-SHADOW);
}

.ShowKid>* {
  display: grid;
  grid-template-columns: 8rem 1fr;
}

.ShowKid>*:first-child {
  display: block;
}

.ShowKid>*:first-child>* {
  color: var(--color-MAIN-HDG);
  text-shadow: 0.1rem 0.1rem 0.1rem rgba(255, 255, 255, 0.2);
  text-align: right;
  margin: 0;
}

.ShowKid>*:first-child>*:last-child {
  margin-bottom: 1rem;
}

.ShowKid .icon {
  --_radius: calc(var(--navBar-BORDER-radius) / 4);
  align-content: center;
  font-size: 1.5rem;
  padding: 0.4rem;
  margin-right: 1rem;
  border-top-left-radius: var(--_radius);
  border-top-right-radius: var(--_radius);
  background: linear-gradient(rgba(255, 255, 255, 0.65), transparent);
  color: var(--color-BG);
  box-shadow: 0.1rem -0.2rem 0.3rem rgba(0, 0, 0, 0.3);
}

.admin-agenda-li .modal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 500;
  display: grid;
  justify-content: center;
  align-items: center;
}


.admin-agenda-li .modal>div {
  position: relative;
  background: var(--color-BG-LIGHT);
  padding: 0.3rem;
  border-radius: var(--navBar-BORDER-radius);
  display: grid;
  gap: 0rem;
}

/* Row Lines */
.admin-agenda-li .modal>div>*>*::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
}

.admin-agenda-li .modal>div>:first-child {
  background: var(--color-BG);
  padding: 0.3rem;
  margin: 0;
  color: var(--color-MAIN-HDG);
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  text-align: center;
}

.admin-agenda-li .modal>div>:last-child {
  padding: 0.5rem;
}

.admin-agenda-li>*:first-child {
  font-weight: bold;
}

.admin-agenda-li>*:not(:is(:first-child, :nth-child(2))) {
  display: grid;
  font-size: 1.5rem;
}

.Welcome {
  display: grid;
  justify-content: center;
  justify-items: center;
  color: var(--color-BG);
  grid-template-columns: 100%;
}

.Welcome-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1em;
}

.Welcome-items {
  background: linear-gradient(#FFFFFF00 5%, #c0c3f842, #FFFFFF00 95%);
  padding: 1.5em 0.5em;
  border-radius: 1em;
}

.Welcome-items>:is(h1, h2, h3, h4, h5) {
  text-align: center;
}

.Welcome-item-img {
  font-size: 3rem;
  display: grid;
  justify-content: center;
  color: var(--color-BG);
  filter: drop-shadow(0.1rem 0.1rem 0.1rem var(--color-BORDER));
}

.Welcome :is(h1, h2, h3, h4, h5) {
  color: var(--color-BG);
  text-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
}

.Welcome>.hero {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "logo h1"
    "logo h2";
  align-items: center;
  column-gap: 2em;
  padding: 2em;
}

.Welcome>.hero>*:nth-child(1) {
  grid-area: logo;
  fill: var(--color-BG);
}

.Welcome>.hero>*:nth-child(2) {
  grid-area: h1;
  margin: 0;
  text-align: center;
}

.Welcome>.hero>*:nth-child(3) {
  grid-area: h2;
  margin: 0;
  text-transform: capitalize;
}

.Welcome>.hero>*:nth-child(3) ul {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1em;
  justify-content: center;
  justify-items: center;
  color: var(--color-MAIN-HDG);
  background: var(--color-BG);
  margin: 0;
  padding: 0.5em;
  border-radius: 0.5em;
}

.Welcome>.hero>*:nth-child(3) ul li::before {
  content: "#";
}

.Hero {
  font-size: 1rem;
  position: relative;
  border-radius: var(--navBar-BORDER-radius);
  border-top: solid 0.2rem var(--color-NavBar-SHADOW);
  box-shadow: var(--SHADOW) var(--color-NavBar-SHADOW);
  padding: 0 1em 1em 1em;
  fill: var(--color-MAIN-HDG);
  aspect-ratio: 16 / 7;
}

.Hero #Bambino {
  filter: drop-shadow(var(--SHADOW) var(--color-SHADOW));
}

.Hero::before {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  content: "";
  width: 100%;
  height: 100%;
  filter: opacity(.5);
  z-index: -1;
}

.Hero.Home::before {
  background: url("./img/hero.jpg") no-repeat center right -9.5rem;
}

.Hero.Feestjes {
  display: grid;
  align-content: center;
}

.Hero.Feestjes::before {
  background: url("./img/hero-feestjes.jpg") center top -2rem;
  background-size: cover;
}

.Feestjes-icon {
  display: grid;
  justify-content: center;
  font-size: 4rem;
  margin-top: 1rem;
}

.photo-col {
  border-radius: var(--navBar-BORDER-radius);
  display: grid;
  align-content: flex-start;
  justify-items: center;
  margin-bottom: 1rem;
  box-shadow: 0rem 0.1rem 0.3rem var(--color-NavBar-SHADOW);
}

.photo-col>img {
  border-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

:is(.no-color, .color, .color2, .colorGrad, .Hero, .col) :is(h1, h2, h3, h4, h5) {
  margin-top: 1em;
}


:is(.no-color, .color, .color2, .colorGrad, .col) a:is(:link, :visited) {
  text-decoration: underline;
  text-underline-offset: 0.2em;
  color: var(--color-MAIN-HDG);
  background: var(--color-BG);
  padding: 0.1em 0.2em 0.3em 0.2em;
  line-height: 1.5em;
  border-radius: 0.2em;
}

:is(.no-color, .color, .color2, .colorGrad, .col) a:is(:active, :hover) {
  /* text-decoration: none; */
  background: var(--color-BORDER);
}

.Hero>h1 {
  margin-top: 0.5em;
  font-size: 2.8em;
  color: var(--color-MAIN-HDG);
  text-shadow: var(--SHADOW) rgba(0, 0, 0, 0.3);
  max-width: 50%;
}

.Hero>h2 {
  margin: 0.2em 0em;
  color: var(--color-BG);
  text-shadow: 0.1rem 0.1rem 0.2rem rgba(255, 255, 255, 1);
}

.Hero-btn:is(:link, :visited) {
  --_padding: 1em;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-MAIN-HDG);
  background: var(--color-BG);
  text-decoration: none;
  padding: var(--_padding);
  line-height: calc(var(--_padding) * 5);
  border-radius: 1em;
  border: solid 0.1em var(--color-MAIN-HDG);
  box-shadow: var(--SHADOW) var(--color-NavBar-SHADOW);
}

.familyFotoBG {
  --_offset: 0.3rem;
  --_size: 20rem;
  background-image: url(./img/Anneke&Kids.png);
  background-size: cover;
  background-repeat: no-repeat;
  filter: brightness(1.2);
  margin: 1em auto;
  width: var(--_size);
  height: var(--_size);
}

.no-color,
.color,
.color2,
.colorGrad {
  padding: 0 1em 1em 1em;
  box-shadow: var(--SHADOW) var(--color-NavBar-SHADOW);
  border-radius: var(--navBar-BORDER-radius);
  border-top: solid 0.2rem var(--color-NavBar-SHADOW);
  color: var(--color-BG);
}

:is(.no-color, .color, .color2, .colorGrad, .Hero)+ :is(.no-color, .color, .color2, .colorGrad, .Hero) {
  margin-top: 1.5rem;
}

.colorGrad {
  color: var(--color-MAIN-TXT);
  fill: var(--color-MAIN-HDG);
  /* background: linear-gradient(#627a4d ,#284b09 15% 85%, #627a4d); */
  /* background: linear-gradient(#627a4d ,#284b09); */
  color: var(--color-BG);
  fill: var(--color-BG);
  background: linear-gradient(#09244b0b, #09244b35);
  /* padding-bottom: 1em; */
}

.colorGrad :is(h1, h2, h3, h4, h5) {
  color: var(--color-BG);
}

.color {
  color: var(--color-LogoPink-hover);
  fill: var(--color-LogoPink-hover);
  background: var(--color-HeroBG);
}

.color2 {
  color: var(--color-LogoPink);
  background: var(--color-ColsBG);
}

.cookies {
  background: var(--color-BG-LIGHT);
  display: grid;
  grid-auto-flow: row;
  gap: 1rem;
}

.cookies ol {
  list-style: decimal;
}

.cookies ol li {
  margin-bottom: .5rem;
}

.cookies :is(h1, h2, h3, h4, h5) {
  padding: 0.3rem 0.5rem;
  margin-bottom: 0.5rem;
  background-color: var(--color-BG);
  color: var(--color-MAIN-HDG);
  border-radius: 0.5rem;
}

.cookies p {
  margin: 0 0.5rem;
}

.footer {
  background: var(--color-Footer-BG);
  border-top: solid 0.3rem var(--color-Footer-SHADOW);
  border-top-left-radius: var(--navBar-BORDER-radius);
  border-top-right-radius: var(--navBar-BORDER-radius);
  color: var(--color-Footer-TXT);
  font-size: 1rem;
  margin-bottom: 0;
  padding: 1rem 0 1.5rem 0;
  display: grid;
  /* grid-auto-flow: column; */
  grid-template-areas:
    "contact sociaal locatie"
    "cookies . voorwaarden";

  gap: 1rem;
  justify-content: space-around;
}

.footerIcon {
  font-size: 1.2rem;
  margin-right: 0.3rem;
  color: var(--color-MAIN-HDG);
}

.footer a:is(:hover, :active) {
  fill: var(--color-NavBar);
  filter: drop-shadow(0.0em 0.1em 0.1em rgba(0, 0, 0, 0.3));
}

.footer .gridItem:nth-child(1) {
  grid-area: contact;
}

.footer .gridItem:nth-child(2) {
  grid-area: sociaal;
}

.footer .gridItem:nth-child(3) {
  grid-area: locatie;
}

.footer .gridItem:nth-child(4) {
  grid-area: cookies;
}

.footer .gridItem:nth-child(5) {
  grid-area: voorwaarden;
}

.footer .gridItem>*+* {
  /* margin-bottom: 1.5em; */
}

.footer :is(h1, h2, h3, h4, h5) {
  color: var(--color-Footer-HDG);
  text-shadow: var(--HDG-SHADOW) rgba(0, 0, 0, 0.2);
}

.footer a {
  color: var(--color-Footer-TXT);
}

.footer .contact {
  width: 12rem;
  font-weight: bold;
}

.footer .locatie {
  font-weight: bold;
}

.footer .contact div:not(:first-child) {
  margin-top: 0.2em;
}

.account {
  display: grid;
  grid-template-columns: minmax(22rem, 100%);
  /* grid-template-columns: minmax(22rem, 40rem); */
  justify-content: center;
}

form.cart {
  --_bg-color: var(--color-BG-MEDIUM);
  --_bg-accent-color: var(--color-BG);
  --_bg-input-color: var(--color-INPUT-BG);
  --_bg-input-color: var(--color-BG-LIGHT);
  --_bg-input-color: white;
  --_hdg-color: var(--color-MAIN-HDG);
  background: white;
  border: 0;
  padding: 0;
  box-shadow: none;
}

form.cart>p {
  padding: 1rem;
  background: var(--color-BG);
}


form.cart>div.timer {
  background: var(--color-BG-LIGHT);
  color: var(--color-BG);
  box-shadow: 0.0rem 0.1rem var(--color-SHADOW);
  padding: 0.3rem 1rem;
  display: grid;
  grid-template-columns: 2rem 1fr;
  align-items: center;
}

form.cart>div.timer>:first-child {
  font-size: 1.5rem;
}

.orderHistory {
  display: grid;
  font-family: var(--font-system-ui-mono);
  font-size: 0.8rem;
}

.orderHistory button {
  background: inherit;
  color: inherit;
  font-weight: bold;
  border: solid 0.1rem var(--color-BORDER);
  border-radius: 0.3rem;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
}

.orderHistory button .icon {
  font-size: 1rem;
  margin-left: 0.5rem;
}

.orderHistory button:is(.kid, .price) {
  background: transparent;
  color: var(--color-BG);
  border: solid 0.01rem var(--color-BG-MEDIUM);
  padding: 0.1rem 0.3rem;
}

.orderHistory button:is(:active, :hover) {
  border-color: var(--color-ColsBG);
}

.orderHistory>* {
  display: grid;
  background: var(--color-BG-LIGHT);
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 0;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
}

.orderHistory>*:nth-child(odd) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}

.orderHistory>*:nth-child(even) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: var(--color-BG-MEDIUM);
  color: var(--color-MAIN-HDG);
  /* background: transparent;
  color: inherit;
  box-shadow: none; */
  font-weight: bold;
  margin-bottom: 1rem;
  border-top: 0;
}

.orderHistory .title {
  font-weight: bold;
  font-size: 1rem;
  text-decoration: underline;
}

.orderHistory .descr {
  font-style: italic;
  margin-bottom: 0.5rem;
}

.orderHistory .datetime {
  font-weight: bold;
  text-transform: capitalize;
  justify-self: right;
  padding: 0.1rem 0.5rem;
  background: var(--color-MAIN-HDG);
  border-radius: 0.3rem;
  color: var(--color-BG);
}

:is(.orderHistory, .refundTransaction) .price::before {
  content: "€ ";
}

.orderHistory .subtotal::before {
  content: "Subtotaal: € ";
}

.orderHistory .total::before {
  content: "Totaal: € ";
}

.orderHistory .price,
.orderHistory .subtotal,
.orderHistory .total {
  justify-self: right;
  padding: 0rem 0.1rem;
}

.orderHistory .kids {
  display: grid;
  grid-template-columns: 1fr 5rem;
  justify-items: right;
  margin-bottom: 0.5rem;
}

.orderHistory .kids .kid {
  text-decoration: underline dotted;
}

.orderHistory .subtotal,
.orderHistory .total {
  background: var(--color-BG-MEDIUM);
  color: var(--color-MAIN-HDG);
  margin-bottom: 2rem;
}

.orderHistory .total {
  background: var(--color-BG);
}

.orderHistory .subtotal:nth-last-child(2),
.orderHistory .total {
  margin-bottom: 0.5rem;
}

.cart-contents {
  display: grid;
  row-gap: 0.3rem;
  /* background: var(--_bg-color); */
  background: transparent;
  color: var(--_bg-accent-color);
  border-radius: calc(var(--navBar-BORDER-radius) / 4);
  margin-bottom: 1rem;
  font-family: var(--font-system-ui-mono);
  font-size: 0.8rem;
  /* padding: 0.1rem; */
}

.cart-contents>* {
  background: var(--_bg-input-color);
  padding: 0.5em 0.5em;
  border-radius: inherit;
  border: double 2px var(--_bg-accent-color);
  display: grid;
  grid-template-columns: 1fr max-content;
}

.cart-contents :is(.span, .subtotal, .btns) {
  grid-area: auto / span 2;
}

.cart-contents>*>*>* {
  display: grid;
  grid-template-columns: 1fr max-content;
  padding: 0em 0.5em;
}

.cart-contents>*>.subtotal {
  background: var(--_bg-color);
  border-radius: 0;
  color: var(--_hdg-color);
}

.cart-contents>*>.btns {
  --_font-size: 1.2rem;
  border-radius: 0;
  color: var(--_bg-accent-color);
  font-size: var(--_font-size);
  /* margin-top: 0.3rem; */
  margin-top: 0;
  margin-bottom: 0.5rem;
  display: grid;
  justify-items: left;
}

.cart-contents>*>.btns a:is(:link, :visited) {
  color: var(--_hdg-color);
  background: var(--_bg-accent-color);
  padding: 0.3rem;
  width: calc(var(--_font-size) * 2.0);
  height: calc(var(--_font-size) * 1.4);
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: calc(var(--navBar-BORDER-radius) / 4);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);

}

.cart-contents>*>.btns a:is(:hover, :active) {
  background: var(--_bg-color);
}

.cart-contents>*>*:is(:first-child, :nth-child(2), :nth-child(3)) {
  font-weight: bold;
  text-decoration: underline;
}

.cart-contents>*:last-child>*:is(:first-child, :nth-child(2), :nth-child(3)) {
  text-decoration: none;
}

.cart-contents>*:last-child {
  background: var(--_bg-accent-color);
  color: var(--_hdg-color);
  border: none;
  box-shadow: 0rem 0.1rem 0rem rgba(255, 255, 255, 0.5);
}

.cart-contents .price::before {
  content: "€ " attr(data-price);
  font-weight: bold;
}

.contactIcon,
.socialIcon {
  font-size: 1.4em;
  fill: var(--color-NavBar);
  filter: drop-shadow(0.0em 0.1em 0.1em rgba(0, 0, 0, 0.3));
}

.socialIcon {
  font-size: 3em;
}

.nieuwsBrief {
  width: 20em;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.grid-shop {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* row-gap: 2em;
  column-gap: 2em; */
  gap: 1em;
}

.kamp {
  color: var(--color-MAIN-HDG);
  max-width: 1fr;
  text-decoration: none;
}

.kamp .picto {
  padding: 1em;
  border-radius: calc(var(--navBar-BORDER-radius) / 4);
  box-shadow: 0em 0.2em 0.2em var(--color-SHADOW);
  height: 9em;
  /* overflow: scroll; */
  overflow: auto;
}

.kamp .bannerHolder {
  position: relative;
}

.kamp .bannerHolder .banner {
  position: absolute;
  top: -6em;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  /* color: var(--color-HDG); */
  text-shadow: 0.1rem 0.1rem 0.1rem var(--color-MAIN-HDG);
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  /* border: solid 1px red; */
}

.KampDetailsEvent {
  margin-bottom: 1rem;
}

.picto h1 {
  font-size: 1.2rem;
  color: var(--color-MAIN-HDG);
  text-transform: uppercase;
  text-align: center;
  text-decoration: underline;
  text-shadow: none;
}

.kamp-kids {
  --_formElement-width: min(100%, 30rem);
  display: grid;
  gap: 0.5em;
  padding-top: 1em;
  margin-top: 1em;
  border-top: dashed 0.1em var(--color-BG);
  width: var(--_formElement-width);
  margin: 0 auto;
}

.kamp-kids div {
  display: grid;
  gap: 0.2em;
}

.kamp-kids label {
  font-weight: bold;
  color: var(--color-BORDER);
  text-align: left;
}

.kamp-kids input,
button,
select,
ul {
  color: var(--color-BG);
}

.kamp-kids ul li {
  background: var(--color-BORDER);
}

.kamp-kids input[type="number"] {
  width: fit-content;
}

.kamp-kids>button {
  background: var(--color-BG);
  color: var(--color-MAIN-HDG);
  font-weight: bold;
  border-color: var(--color-BG);
  box-shadow: var(--SHADOW) var(--color-SHADOW);
}

.kamp-kids>button:is(:hover, :active) {
  background: var(--color-BORDER);
}

.aantal-kids {
  display: grid;
}

.aantal-kids>*:is(h1, h2, h3) {
  margin: 0 0 0.3rem 0;
  padding: 0;
}

.aantal-kids>.deelnemers {
  background: var(--color-BG-MEDIUM);
  border-radius: inherit;
  padding: 0.3rem;
}

.deelnemers>.checkbox>label {
  color: var(--color-MAIN-HDG);
  font-weight: normal;
}

.aantal-kids>.aantal-kids :is(h1, h2, h3, h4, h5, h6) {
  margin: 0;
  padding: 0;
}

.kids {
  display: grid;
  gap: 0.3rem;
  justify-items: center;
}

/* form input[type="submit"],
form>ul {
  color: #FFF;
  background: var(--color-BG);
  border-color: var(--color-BG);
  box-shadow: var(--navBar-SHADOW) rgba(0, 0, 0, 0.4);
}

form input[type="submit"]:is(:hover, :active) {
  outline-style: solid;
  outline-color: var(--color-INPUT-BG);
  outline-width: 0.15em;
  outline-offset: -0.1em;
} */

.kids>button {
  color: #FFF;
  background: var(--color-BG);
  border-color: var(--color-BG);
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem;
  font-weight: bold;
  box-shadow: var(--navBar-SHADOW) rgba(0, 0, 0, 0.4);
}

.kids>button:is(:hover, :active) {
  outline-style: solid;
  outline-color: var(--color-INPUT-BG);
  outline-width: 0.15em;
  outline-offset: -0.1em;
}

.kids-list {
  justify-self: normal;
  background: var(--color-BG-LIGHT);
  border-radius: 0.5rem;
  overflow: hidden;
}

.kids-list-item {
  display: grid;
  gap: 0.3rem;
  grid-template-columns: 2rem repeat(2, 1fr) 3rem;
  align-items: center;
  padding: 0.3rem;
}

.kids-list-item:not(:last-child) {
  border-bottom: solid white 0.1rem;
}

.kids-list-item:is(:hover, :active) {
  background: var(--color-BG);
  color: var(--color-MAIN-HDG);
}

/* .kids-list-item:first-child {
  font-weight: bold;
} */

.kids-list-item>*:first-child {
  display: grid;
  align-items: center;
  margin: 0;
  padding: 0;
  background: inherit;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  min-height: 2rem;
}

.kids-list-item>input {
  min-width: 8rem;
}

.kids-list-item>.edit {
  justify-self: center;
  display: grid;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  background: inherit;
  font-size: 1.5rem;
  min-height: 2rem;
  width: 3rem;
}

.agenda,
.admin-agenda-cal,
.kamp-event {
  margin-top: 1em;
  padding: 1em;
  background: #EFEFEF;
  border-radius: calc(var(--navBar-BORDER-radius) / 4);
  box-shadow: 0em 0.2em 0.2em var(--color-SHADOW);
}

.admin-agenda-cal {
  margin: 0;
  height: fit-content;
  padding: 0.5em;
}

.admin-agenda-cal>*:last-child {
  padding-top: 0.5rem;
  text-align: right;
}

.kamp-event {
  color: var(--color-BG);
}

.agenda>.buttons {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  /* background-color: #000; */
}

.agenda>.buttons>div {
  display: grid;
  grid-auto-flow: column;
}

.agenda>.buttons a.btn {
  position: static;
  text-decoration: none;
  border-radius: calc(var(--navBar-BORDER-radius) / 4);
  padding: 0.5em;
  text-align: center;
}

.agenda>.buttons a.btn.selected {
  background: var(--color-BORDER);
}

.agenda :is(h1, h2, h3, h4, h5) {
  margin-bottom: 0.3em;
}

.agenda>.week {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(7, 1fr);
  grid-template-columns: 4rem 1fr;
  background: var(--color-BG);
  gap: 1px;
  border-radius: calc(var(--navBar-BORDER-radius) / 4);
}

.agenda>.month {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background: var(--color-BG);
  gap: 1px;
  border-radius: calc(var(--navBar-BORDER-radius) / 4);
}

.admin-agenda-cal>.month {
  display: grid;
  width: fit-content;
  grid-template-columns: repeat(7, 2rem);
  background: var(--color-BG);
  gap: 1px;
  border-radius: calc(var(--navBar-BORDER-radius) / 4);
}

.agenda> :is(.month, .week)>.title:first-child,
.admin-agenda-cal> :is(.month, .week)>.title:first-child {
  border-top-left-radius: inherit;
}

.agenda> :is(.month, .week)>.title:nth-child(7),
.admin-agenda-cal> :is(.month, .week)>.title:nth-child(7) {
  border-top-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.agenda> :is(.month, .week)>.title,
.admin-agenda-cal> :is(.month, .week)>.title {
  text-align: center;
  background: var(--color-BG);
  color: var(--color-Footer-HDG);
  font-weight: bold;
  padding: 0.3em;
}

.agenda> :is(.month, .week)>.title,
.admin-agenda-cal> :is(.month, .week)>.title {
  outline: var(--color-BORDER) 1px solid;
}

.agenda>.month>.title:nth-child(7),
.admin-agenda-cal>.month>.title:nth-child(7) {
  border-bottom-left-radius: 0;
}

.agenda>.week>.title:nth-child(7) {
  border-top-right-radius: 0;
}

.agenda> :is(.month, .week)>.day {
  background: var(--color-Footer-HDG);
  color: var(--color-BG);
  padding: 0.3em;
  height: 6.2rem;
  overflow: hidden;
}

.admin-agenda-cal> :is(.month)>.day {
  background: var(--color-Footer-HDG);
  color: var(--color-BG);
  padding: 0.3em;
  height: 1.5rem;
  overflow: hidden;
}

.agenda>.month>.day>.date,
.admin-agenda-cal>.month>.day>.date {
  text-align: right;
  font-weight: bold;
  font-size: 1rem;
}

.agenda> :is(.month, .week)>.day>a,
.admin-agenda-cal> :is(.month, .week)>.day>a {
  text-decoration: none;
}

.admin-agenda-cal {
  width: fit-content;
  margin-bottom: 1rem;
  background: var(--color-BG-LIGHT);
}

.admin-agenda-cal>*:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  font-weight: bold;
  display: grid;
  grid-template-columns: 2.5rem 1fr 2.5rem;
  justify-items: center;
  align-items: center;
  /* background: var(--color-BG); */
  color: var(--color-BG);
  font-size: 1.2rem;
  line-height: 2rem;
}

.admin-agenda-cal>*:first-child>*:first-child,
.admin-agenda-cal>*:first-child>*:last-child {
  font-size: 1.5rem;
  display: grid;
}

.agenda> :is(.month, .week)>.day .event {
  font-size: 0.7rem;
  color: #FFF;
  border-radius: calc(var(--navBar-BORDER-radius) / 4);
  background: var(--color-EVENT-BG);
  border: solid 1px var(--color-EVENT-SHADOW);
  opacity: 1;
  padding: 0.3em;
  overflow: hidden;
  box-shadow: 0.1em 0.1em 0.3em var(--color-EVENT-SHADOW);
}

.event> :is(.title, .content, .info) {
  min-width: 6rem;
  height: 1rem;
}

.event>.title {
  font-weight: bold;
  font-size: 1.1em;
}

.event>.content {
  font-weight: normal;
  max-height: 1.1em;
  overflow: hidden;
}

.event>.info {
  max-width: fit-content;
  height: 2rem;
  display: grid;
  background: rgba(200, 200, 200, 0.2);
  outline: solid 1px rgba(0, 0, 0, 0.1);
  margin-top: 0.2rem;
  padding: 0.2rem 0.2rem;
  border-radius: inherit;
  gap: 1px;
}

.event>.info>*+* {
  border-top: solid 1px rgba(0, 0, 0, 0.2);
}

.event>.info .availables::after {
  content: " beschikbaar";
}

.event>.info .price::before {
  content: "€ " attr(data-price) " / kind";
}

.agenda> :is(.month, .week)>.day .event.selected {
  /* position: relative; */
  --_outlineWidth: 0.2em;
  outline: solid var(--_outlineWidth) var(--color-BG);
  outline-offset: calc(var(--_outlineWidth) * -1);
  background: var(--color-BORDER) !important;
}

.agenda> :is(.month, .week)>.day .event.disabled {
  /* filter: opacity(.5); */
  background-color: rgba(0, 0, 0, 0.1) !important;
  cursor: default;
  color: rgba(0, 0, 0, 0.6);
}

/* .agenda > :is(.month, .week) > .day .event.selected::before {
  content: "*";
  font-size: 1rem;
  top: 0;
  right: 0.5em;
  color: #000;
  background-color: #FFF;
  border: solid 1px #000;
  padding: 0;
  position: absolute;
} */

.eventMiniMode {
  --_size: 0.6em;
  position: absolute;
  margin: -0.25em;
  /* background: #55a014; */
  /* background: radial-gradient(#55a014 40%, transparent); */
  /* background: radial-gradient(var(--color-BG) 40%, transparent); */
  /* background: radial-gradient(transparent 10%, var(--color-BG)); */
  background: radial-gradient(var(--color-ERROR) 40%, gold 55%, transparent 75%);
  width: var(--_size);
  height: var(--_size);
  border-radius: var(--_size);
}

/* .eventMiniModeSelected {
  --_size: 1.8rem;
  position: absolute;
  margin: -0.3em -0.15em;
  width: var(--_size);
  height: var(--_size);
  border-radius: var(--_size);
  background: radial-gradient(transparent 60%, var(--color-BG-MEDIUM) 60%);
} */

.eventMiniModeSelected {
  background: var(--color-SHADOW) !important;
  color: var(--color-MAIN-HDG) !important;
}


.activiteit {
  display: grid;
  grid-auto-flow: row;
  gap: 0.1em;
  justify-content: center;
  align-items: flex-start;
  box-shadow: var(--SHADOW) var(--color-SHADOW);
  background-color: var(--color-BG);
  color: var(--color-Footer-HDG);
  font-size: 1rem;
  text-decoration: none;
  border-color: var(--color-BG);
}

.activiteit:is(:active, :hover):not(:disabled) {
  box-shadow: none;
  background: var(--color-BORDER);
}

.activiteit.sm {
  max-width: 14rem;
  border-radius: calc(var(--navBar-BORDER-radius) / 4);
  padding: calc(var(--navBar-BORDER-radius) / 4);
}

.activiteit.md {
  max-width: 22rem;
  border-radius: calc(var(--navBar-BORDER-radius) / 2);
  padding: calc(var(--navBar-BORDER-radius) / 2);
}

.activiteit.lg {
  max-width: 30rem;
  border-radius: calc(var(--navBar-BORDER-radius) / 2);
  padding: calc(var(--navBar-BORDER-radius) / 2);
}

.activiteit>.title {
  font-weight: bold;
  text-align: center;
  color: var(--color-MAIN-HDG);
  border-radius: 0;
  border: solid 0.1rem var(--color-SHADOW);
  box-shadow: var(--HDG-SHADOW) var(--color-HDG-SHADOW);
  margin: 0;
  padding: 0.5em;
}

.activiteit>.text {
  margin-top: 0.5em;
  text-align: left;
  color: var(--color-MAIN-TXT);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.activiteit>div {
  border-radius: inherit;
}

.activiteit>div>img {
  width: 100%;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  outline: solid 0.1rem var(--color-BG);
  outline-offset: -0.2rem;
}

.underConstruction {
  background: #222222;
  border-radius: calc(var(--navBar-BORDER-radius) / 2);
  padding: 0.5em 0;
  margin: 1em 0;
  text-align: center;
  overflow: hidden;
}

.underConstruction div {
  background: repeating-linear-gradient(135deg, transparent, var(--color-CONSTRUCTION) 0em, transparent 0.6em);
  width: 100%;
  height: 1em;
}

.underConstruction :is(.icon, h1, h2, h3, h4, h5) {
  color: var(--color-CONSTRUCTION);
  padding-bottom: 1em;
  font-style: italic;
}

.underConstruction .icon {
  background: transparent;
  font-size: 2.5rem;
  padding: 0;
  margin-top: 0.5em;
}

.underConstruction :is(.icon, h1, h2, h3, h4, h5)::before {
  content: attr(data-text);
  position: absolute;
  transform-origin: bottom;
  transform: rotateX(180deg);
  line-height: 0.8em;
  font-style: italic;
  background-image: linear-gradient(0deg, var(--color-CONSTRUCTION) 0%, transparent 90%);
  -webkit-background-clip: text;
  color: transparent;
  opacity: 0.7;
}

/* Mobile width */
@media (max-width: 768px) {

  .Hero>h1 {
    max-width: 100%;
  }

  .Welcome-grid {
    display: grid;
    grid-template-columns: auto;
    row-gap: 0;
  }

  .Welcome>.hero {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "logo"
      "h1"
      "h2";

    justify-items: center;
    row-gap: 0.5em;
    padding: 2em;
  }

  .Welcome>.hero>*:nth-child(3) ul {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid {
    grid-auto-flow: row;
    gap: 0;
  }

  .grid.center {
    gap: 1rem;
  }

  .flex {
    flex-direction: column;
  }

  .mobileLogoTitle {
    display: flex;
    justify-content: center;
  }

  .fullLogo {
    display: inline-block;
    fill: #FFF;
    max-height: var(--navBar-height);
    padding: 0.3rem;
  }

  .btnMobileLogo {
    grid-area: 1 / 3;
    display: block;
    background: var(--color-LogoPink-hover);
    border-radius: 1rem;
    border: solid 0rem var(--color-LogoPink);
    padding: 0;
    height: var(--navBar-height);
  }

  .btnMobileLogo div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .burger {
    color: var(--color-NavBar);
    font-size: 3rem;
  }

  .navBar {
    display: grid;
    grid-template-columns: 1fr max-content max-content;
    justify-items: left;
    z-index: 1;
  }

  .navSession {
    grid-area: 1 / 2;
    margin-right: 2rem;
  }

  .nav {
    position: absolute;
    width: var(--navBar-Mobile-width);
    top: var(--navBar-height);
    right: 1.6rem;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--color-LogoPink-hover);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border: solid 0.3rem var(--color-LogoPink);
    border-top: 0;
    padding: 0.5rem 0;
    box-shadow: 0 1.2rem 2rem -1rem #000;
    background: rgba(148, 124, 156, 0.95);
  }

  .nav li+li {
    margin: 0em 0em 0.1em 0em;
  }

  .nav li:first-child {
    margin: 0 auto 0.1em;
  }

  .nav li a div {
    font-size: 1rem;
    width: calc(var(--navBar-Mobile-width) - 0.6rem);
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem 0rem 0.5rem 1rem;
  }

  .nav li:first-child a div {
    padding: 0;
  }

  .logo {
    margin: 0 auto;
    padding: 0;
  }


  /* .kids-list-item>* {
    grid-column: span 5;
  } */

  .closeMenuClick {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .footer {
    text-align: center;
    justify-items: center;

    grid-template-areas:
      "contact"
      "sociaal"
      "locatie"
      "cookies"
      "voorwaarden";
  }

  .footer>*:nth-child(2)>div.flex-row {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-evenly;
  }

  .footer>*:not(:last-child) {
    border-bottom: dotted 0.1em var(--color-NavBar-SHADOW);
    padding-bottom: 1rem;
  }
}


.closeMenuClick_hide {
  display: none;
}

.hide {
  display: none;
}

.show {
  display: inline-flex;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}